body {
  overflow: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  position: absolute;
  top: 100px;
  height: 20vmin;
  width: 20vmin;
  pointer-events: none;
  transition: top 0.1s ease-in-out;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.theZone {
  width: 100vw;
  top: 100px;
  height: 20vh;
  background-color: #ff000077;
  position: absolute;
}

.Button {
  font-size: calc(10px + 1vmin);
  font-weight: bold;
  color: white;
  background-color: #51caeb;
  border: 2px solid white;
  height: 3rem;
  width: 35vw;
  border-radius: 2rem;
  position: absolute;
}

.startButton {
  bottom: 90px;
  background-color: #317aeb;
}

.tapButton {
  bottom: 20px;
}

.tapButton:active {
  background-color: darkcyan !important;
}

.Score {
  width: 100vw;
  top: 0px;
  height: 5vh;
  background-color: #00000077;
  position: absolute;
}

.timerText {
  padding-left: 3rem;
}

.gameMessage {
  max-width: 50vw;
  background-color: #00000077;
  padding: 1rem;
  white-space: pre-wrap;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
